import React, { useState } from "react";

import { SanityResource, SanityResourceCategory } from "@graphql-types";
import { Container } from "@util/standard";
import { CategoryFilterBar } from "@global";
import ResourcesGrid from "./resourcesGrid";
import { CategoryName } from "../../global/global.styles";

interface Props {
  data: SanityResourceCategory[];
  resources: SanityResource[];
}

function ResourceFilterBar({ data, resources }: Props) {
  if (data == null) return null;

  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(999);
  const [mobileFilterVisible, setMobileFilterVisible] = useState(false);

  const currentCategoryTitle = data[currentCategoryIndex] && data[currentCategoryIndex].title;

  const handleCategoryClick = (index: number) => () => {
    setCurrentCategoryIndex(index);
    setMobileFilterVisible(false);
  };

  return (
    <CategoryFilterBar
      mobileVisibility={mobileFilterVisible}
      setMobileVisibility={setMobileFilterVisible}
      filterResultChildren={
        <ResourcesGrid data={resources} currentCategory={currentCategoryTitle ?? ""} />
      }
    >
      <Container width="100%" flexDirection="column">
        <Container flexDirection="column">
          <CategoryName onClick={handleCategoryClick(999)} selected={currentCategoryIndex === 999}>
            All Resources
          </CategoryName>
          {data.map((category, index) => {
            if (!category) return null;

            return (
              <CategoryName
                onClick={handleCategoryClick(index)}
                selected={index === currentCategoryIndex}
                key={category._id}
              >
                {category.title}
              </CategoryName>
            );
          })}
        </Container>
      </Container>
    </CategoryFilterBar>
  );
}

export default ResourceFilterBar;
