import React from "react";
import { PageProps, graphql } from "gatsby";

import { Query, SanityResourceCategoryConnection, SanityResourceConnection } from "@graphql-types";
import { Hero } from "@global";
import Layout from "@shared/layout";
import SEO from "@shared/seo";
import ResourceFilterBar from "@components/resources/resourceFilterBar";

interface Data extends Query {
  resourceCategories: SanityResourceCategoryConnection;
  resources: SanityResourceConnection;
}

interface Props extends PageProps {
  data: Data;
}

export default function ResourcesPage({ data }: Props) {
  if (data.sanityResourcesPage == null) {
    return null;
  }

  const { seo, hero } = data.sanityResourcesPage;
  const resourceCategories = data.resourceCategories.nodes;
  const resources = data.resources.nodes;

  return (
    <>
      <SEO seoData={seo} />
      <Layout>
        <Hero data={hero} />
        <ResourceFilterBar data={resourceCategories} resources={resources} />
      </Layout>
    </>
  );
}

export const query = graphql`
  {
    sanityResourcesPage {
      seo {
        ...sanitySeo
      }
      hero {
        ...sanityHero
      }
    }
    resourceCategories: allSanityResourceCategory {
      nodes {
        _id
        title
      }
    }
    resources: allSanityResource {
      nodes {
        _id
        resourceName
        regions {
          countryCode
        }
        resourceLink
        resourceImage {
          ...sanityFullWidthImage
        }
        id
        category {
          title
          _id
        }
      }
    }
  }
`;
