import React, { useState } from "react";
import styled from "styled-components";

import { Container, P, AbsoluteContainer } from "@util/standard";
import { SanityResource } from "@graphql-types";
import { Image, SuccessToastMessage } from "@global";
import { assets, colors, colorsRGB, TABLET_BREAKPOINT } from "@util/constants";
import { useCheckScreenWidth } from "@util/hooks";
import { isBrowser } from "@util/helper";
import { toast } from "react-toastify";

interface Props {
  data: SanityResource;
  hasBorderLeft?: boolean;
  hasBorderBottom?: boolean;
}

const CardWrapper = styled.div<{ borderLeft?: boolean; borderBottom?: boolean }>`
  ${({ borderLeft }) => borderLeft && ` border-left: 1px solid ${colorsRGB.navy(0.4)};`};
  ${({ borderBottom }) => borderBottom && ` border-bottom: 1px solid ${colorsRGB.navy(0.4)};`};
  padding: 40px 6%;
  z-index: 1;
  a {
    color: ${colors.navy};
  }
  a:visited {
    color: ${colors.navy};
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    border-left: none;
    border-right: none;
    border-bottom: 1px solid ${colorsRGB.navy(0.4)};
    padding: 30px 0px 0px 0px;
  }
`;

const ImageWrapper = styled(Container)`
  position: relative;
  height: 292px;
  .gatsby-image-wrapper {
    border-radius: 4px;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    height: 302px;
  }
`;

const StyledAbsoluteContainer = styled(AbsoluteContainer)`
  bottom: 0px;
  width: 100%;
  background-color: ${colorsRGB.white(0.5)};
  z-index: 5;
  cursor: pointer;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: none;
  }
`;

function ResourceCard({ data, hasBorderLeft, hasBorderBottom }: Props) {
  if (data == null) return null;

  const { resourceName, resourceLink, resourceImage } = data;
  const [hideButton, setHideButton] = useState(true);

  const { isMobileWidth, isTabletWidth } = useCheckScreenWidth();

  const handleClick = () => {
    if (isBrowser() && resourceLink) {
      toast.success(<SuccessToastMessage />);
      setTimeout(() => {
        window.open(resourceLink as string, "_blank");
      }, 3000);
    }
  };

  return (
    <CardWrapper
      borderLeft={hasBorderLeft}
      borderBottom={hasBorderBottom}
      onMouseEnter={() => setHideButton(false)}
      onMouseLeave={() => setHideButton(true)}
      onClick={isTabletWidth || isMobileWidth ? handleClick : undefined}
    >
      <ImageWrapper>
        {resourceImage && resourceImage.asset ? (
          <Image isBackground data={resourceImage} />
        ) : (
          <img src={assets.productDefaultImage} style={{ width: "100%", objectFit: "cover" }} />
        )}
        {!hideButton && (
          <StyledAbsoluteContainer onClick={handleClick}>
            <P margin="16px auto" textAlign="center" fontWeight="medium">
              Download
            </P>
          </StyledAbsoluteContainer>
        )}
      </ImageWrapper>
      <div>
        <h4>{resourceName}</h4>
      </div>
    </CardWrapper>
  );
}

export default ResourceCard;
