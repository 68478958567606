import React from "react";

import { Maybe, SanityResource } from "@graphql-types";
import ResourceCard from "./resourceCard";
import { calculateGridItemBorders } from "@util/helper";
import { StandardGridLayout } from "@global";

interface Props {
  data: SanityResource[];
  currentCategory: Maybe<string>;
}

function ResourcesGrid({ data, currentCategory }: Props) {
  if (data == null) return null;

  const filteredResources = data.filter(resource => {
    if (currentCategory === "")
      return (
        resource.regions &&
        resource.regions.some(region => region?.countryCode === process.env.GATSBY_REGION)
      );
    return (
      currentCategory === resource.category?.title &&
      resource.regions &&
      resource.regions.some(region => region?.countryCode === process.env.GATSBY_REGION)
    );
  });

  const lastItem =
    filteredResources &&
    filteredResources.indexOf(filteredResources[filteredResources.length - 1]) + 1;

  return (
    <StandardGridLayout noBorderTop>
      {filteredResources &&
        filteredResources.map((resource, index) => {
          if (!resource) return null;

          const { lastRowHasOne, lastRowHasTwo, lastRowHasThree, noBorderLeft } =
            calculateGridItemBorders(index, lastItem, data.length);

          return (
            <ResourceCard
              data={resource}
              key={resource.id}
              hasBorderLeft={!noBorderLeft}
              hasBorderBottom={!lastRowHasOne && !lastRowHasTwo && !lastRowHasThree}
            />
          );
        })}
    </StandardGridLayout>
  );
}

export default ResourcesGrid;
